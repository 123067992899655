import React from "react";
import PropTypes from 'prop-types';

import GenericModal from "../GenericModal";
import warningCircle from '../../../../assets/images/warning.svg';

DeleteAdditionalContext.propTypes = {
    onClose: PropTypes.func,
    handleDelete: PropTypes.func
};

export default function DeleteAdditionalContext(props) {

    const { onClose, handleDelete } = props;

    const getModalBody = () => {
        return (
            <div className="msgConfirm">
                Confirm you would like to remove this KPIs/Metrics.
            </div>
        )
    }

    return (
        <GenericModal
            modalProperties={{
                show: true,
                className: "confirmModal startIconClose modalMotifFooterModal",
                modalType: "alert",
                headerIcon:<img className='warningCircle' alt='icon' src={warningCircle} />,
                title: "Remove KPIs/Metrics",
                body: getModalBody(),
                customCloseButtonTitle: "Close",
                rightButtonVariant: "primary",
                leftButtonVariant: "secondary",
                rightButtonSize: "medium",
                leftButtonSize: "medium",
                leftButtonText: "Cancel",
                rightButtonText: "Confirm",
                footerCssClass: "modal-buttons",
                closeHandler: onClose,
                leftButtonHandler: onClose,
                rightButtonHandler: handleDelete
            }}
        >
        </GenericModal>
    )

} 