/**
 * Service to manage API calls for Analysis
 * Modified Date : 11-09-2024
 */

import axios from "./axiosService.js";
import CommonService from "./commonService.js";

//  Local mock data imports
import analysisSettings from '../assets/data/analysisSettings.json';
import tableAnalysis from '../assets/data/tableAnalysis.json';
import lineItemsStatus from '../assets/data/lineItemsStatus.json';

class AnalysisService {
  commonService = new CommonService();
  constructor() {
    this.apiPointer = process.env.REACT_APP_PLATFORM_API_POINTER;
    this.apiFolder = process.env.REACT_APP_PLATFORM_API_FOLDER;
    this.apiAnalysisFolder = process.env.REACT_APP_PLATFORM_API_ANALYSIS_FOLDER;
    this.rowStatusAPI = process.env.REACT_APP_PLATFORM_API_FUNCTION_APP_URL;
    this.lineAnalysisAPI =
      process.env.REACT_APP_PLATFORM_API_FUNCTION_APP_LINE_ANALYSIS_URL;
       this.apiTransferToPPT=process.env.REACT_APP_PLATFORM_API_TRANSFER_PPT;
    //  Headers
    this.headers = { "Content-type": "application/json; charset=UTF-8" };

    //  API url methods
    this.apiUrl = {
      getAnalysisSettings: '/getAnalyseSetting',
      transferToPPT: '/transferToPPT',
    };

    //  List of API endpoints to point to local mock data
    this.mockApiUrl = [
      // 'getAnalysisSettings',
      // 'getTableAnalysisData',
      // 'getRowStatus'
    ];
  }

  getRowStatus = async (tableid, projectid, userId) => {
    if (this.apiPointer.toLowerCase() === "local" || this.mockApiUrl.includes("getRowStatus")) {
      return Promise.resolve(lineItemsStatus);
    } else {
    const requestPayload = {
      tableId: tableid,
      projectId: projectid,
      userId: userId,
    };
    const vApiEndpoint = this.commonService.getAPIPollingUrl();
    try {
      return await axios
        .post(vApiEndpoint, requestPayload)
        .then((data) => {
          const returnData = data?.data;
          return returnData;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "AnalysisService",
        "getRowStatus"
      );
    }
    }
  };

/**
   * Function to call add transfer API to save tansfer
   * @param {*} projectId
   * @param {*} resultId
   * @param {*} sourceSystem
   * @param {*} answer
   * @param {*} userId
   * @returns
   */
async transferToPPT(projectId,resultId,sourceSystem,answer,userId) {
  if (this.apiPointer.toLowerCase() === "local") {
    return Promise.resolve({ message: "Updated the Database!" });
  } else {
    const vUrl = this.apiTransferToPPT+ this.apiUrl.transferToPPT; 
                
    const transferToPPTRequest = {
      projectId:projectId,
      resultId: resultId,
      sourceSystem:sourceSystem,
      answer:answer,
      userId:userId 
    };
    const vParams = {
      method: "post",
      url: vUrl,
      headers: this.headers,
      data: transferToPPTRequest,
    };
    const response = await axios.request(vParams).catch((error) => {
      this.commonService.logException(
        error,
        "analysisService",
        "Transfer commentary to PowerPoint"
      );
    });
    return response?.data;
  }
}

  async initLineAnalysis(vData) {
    const finalRowData = [];
    vData.checkRowIds.forEach((rowid) => {
      const rowData = {
        rowId: rowid,
        promptIds: vData.promptIds,
        pipelineSettings: {
          model_type: "gpt-4",
          temperature: 0.2,
        },
      };
      finalRowData.push(rowData);
    });
    const vDocumentList = [];
    vData.documents.forEach(element => {
      vDocumentList.push(element.documentName);
    });
    const vDocumentIdList = [];
    vData.documents.forEach(element => {
      vDocumentIdList.push({
        id: element.document_UUID,  //  documentId
        docName: element.documentName
      });
    });
    const requestPayload = {
      projectId: vData.projectId,
      tableId: vData.tableId,
      method: "lineDescription",
      documentList: vDocumentList,
      documentIdList: vDocumentIdList,
      qnaFlag: vData.qnaFlag,
      fulltableFlag: vData.fulltableFlag,
      rowIds: finalRowData,
      isCommentaryExcel: vData.isCommentaryExcel,
      allow_create: false,
      wait_until_complete: false,
      userid: vData.userId,
      userroles: ["consumer", "EY Technology"],
      delDocument: "None",
      delUser: "None",
      delTable: "None",
      delVectorstore: "None",
      delPrompt: "None",
      delResult: "None",
      delRelationship: "None",
      delExtraction: "None",
      delAdjustment: "None",
      delCollection: "None",
    };
    const vApiEndpoint = this.commonService.getAPIAnalysisUrl();
    const response = await axios
      .post(vApiEndpoint, JSON.stringify(requestPayload))
      .catch((error) => {
        this.commonService.logException(error, "Start Line Analysis");
      });

    return response?.status;
  }

  async initialiseTableAnalysis(vData) {
    const requestPayload = {
      projectId: vData.projectId,
      tableId: vData.tableId,
      method: "tableAnalysis",
      promptIds: {
        userPromptId: "5baf7ea241c040baa8db33946b987c0f",
        systemPromptId: "369677a741b943a6af5faf276dce40b6",
      },
      pipelineSettings: {
        model_type: "gpt-4",
        temperature: 0.2,
      },
      isCommentaryExcel: vData.isCommentaryExcel,
      allow_create: false,
      wait_until_complete: false,
      userid: vData.userId,
      userroles: ["consumer", "EY Technology"],
      delDocument: "None",
      delUser: "None",
      delTable: "None",
      delVectorstore: "None",
      delPrompt: "None",
      delResult: "None",
      delRelationship: "None",
      delExtraction: "None",
      delAdjustment: "None",
      delCollection: "None",
    };
    const vApiEndpoint = this.commonService.getAPIAnalysisUrl();
    const response = await axios
      .post(vApiEndpoint, JSON.stringify(requestPayload))
      .catch((error) => {
        this.commonService.logException(error, "Start Table Analysis");
      });

    return response?.status;
  }

  async getTableAnalysisData(tableId) {
    if (this.apiPointer.toLowerCase() === "local" || this.mockApiUrl.includes("getTableAnalysisData")) {
      return Promise.resolve(tableAnalysis);
    } else {
      const vApiEndpoint = this.commonService.getAPIUrl();
      const url = vApiEndpoint + this.apiAnalysisFolder + "/getLatestTableAnalysis";
      const requestPayload = {
        queueMethod: "tableAnalysis",
        tableId: "" + tableId + "",
      };
      try {
        return await axios.post(url, requestPayload).then((data) => {
          const returnData = data?.data;
          return returnData;
        });
      } catch (error) {
        this.commonService.logException(
          error,
          "AnalysisService",
          "Get Table Analysis Data"
        );
      }
    }
  }

  async getAnalysisSettings(vData) {
    if (this.apiPointer.toLowerCase() === "local" || this.mockApiUrl.includes("getAnalysisSettings")) {
      return Promise.resolve(analysisSettings);
    } else {
      const vApiEndpoint = this.commonService.getAPIUrl();
      const vUrl = vApiEndpoint + this.apiFolder + this.apiUrl.getAnalysisSettings;
      const vParams = {
        method: "post",
        url: vUrl,
        headers: this.headers,
        data: JSON.stringify(vData),
      };
      const response = await axios.request(vParams).catch((error) => {
        this.commonService.logException(error, "Analysis service", "Get analysis settings");
      });
      return response?.data;
    }
  }
}

export default AnalysisService;
