import React, { useState } from "react";
import { useSelector } from "react-redux";

import GenericModal from "../GenericModal";
import './ExportQnA.scss';
import { MotifIcon, MotifLabel } from "@ey-xd/motif-react";

import TableService from '../../../../services/tableService';


export default function ExportQnA(props) {

    const tableService = new TableService();

    const { user } = useSelector(state => state.user);
    const { show, onClose, fileName, downloadedBy, downloadedOn, showConfirmation, receiveExportStatus } = props;
    // const [ setDirectoryName ] = useState("");
    const [isConfirmed, setIsConfirmed] = useState(false);


    const handleClose = () => {
        onClose();
    }

    const handleConfirm = () => {
        setIsConfirmed(true);
    }

    const handleExport = async () => {

        // Make API call, trigger file download
        const response = await tableService.requestExportAndGetDownloadURL(
            {
                "tableId": props.tableId,
                "projectId": props.projectId,
                "userId": user.username
            }
        )

        if (response) {
            const type = response.headers.get("content-type");
            const contentDisposition = response.headers['content-disposition'];
            let fName = 'default_filename.xlsm';  // Fallback filename

            if (contentDisposition) {
                fName = contentDisposition.match(/filename="?([^"]+)"?/)[1];
            }
            const blob = new Blob([response.data], { type: type });
            let url = window.URL.createObjectURL(blob);

            // Create a link element
            let a = document.createElement("a");
            a.href = url;
            a.download = fName;
            a.click();

            // Cleanup
            URL.revokeObjectURL(a.href);

            receiveExportStatus(response.status);
            handleClose();
        }
        else {
            receiveExportStatus(response.status);
            handleClose();
        }
    }

    // const saveFilePicker = async () => {
    //     try {
    //         const fileHandle = await window.showSaveFilePicker({ suggestedName: fileName })
    //         setDirectoryName(fileHandle.name);
    //     } 
    //     catch (error) {
    //         console.error('Directory selection was canceled or failed', error);
    //     }
    // }

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const day = date.getDate();
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
    }

    const getModalBody = () => {
        return (
            <>
                {
                    showConfirmation && !isConfirmed ?
                        (
                            <div>
                                '{fileName}' was exported by {downloadedBy} on {formatTimestamp(downloadedOn)} but has not yet been uploaded.<br /><br />Confirm you would like to continue.
                            </div>
                        )
                        :   // else
                        (
                            <div className="fileNameContainer">
                                <MotifLabel className="fileLabel">File name</MotifLabel>
                                {fileName}
                                {/* <MotifLabel>Export file to</MotifLabel> {directoryName}
                                <MotifButton variant="secondary" onClick={saveFilePicker}>Select folder</MotifButton> */}
                            </div>
                        )
                }
            </>
        )
    }

    return (
        <GenericModal
            modalProperties={{
                show: show,
                className: "confirmModal startIconClose modalMotifFooterModal",
                modalType: showConfirmation && !isConfirmed ? "alert" : "basic",
                title: "Export Q&A",
                body: getModalBody(),
                customCloseButtonTitle: "Close",
                rightButtonVariant: "primary",
                leftButtonVariant: "secondary",
                rightButtonSize: "medium",
                leftButtonSize: "medium",
                leftButtonText: "Cancel",
                rightButtonText: showConfirmation && !isConfirmed ? "Confirm" : "Export",
                closeHandler: handleClose,
                leftButtonHandler: handleClose,
                rightButtonHandler: showConfirmation && !isConfirmed ? handleConfirm : handleExport,
                footerCssClass: 'modal-buttons',
                headerIcon: showConfirmation && !isConfirmed ? <MotifIcon
                                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMSAyMWgyMkwxMiAyIDEgMjF6bTEyLTNoLTJ2LTJoMnYyem0wLTRoLTJ2LTRoMnY0eiIvPjwvc3ZnPg=="
                                style={{
                                color: '#FF9831',
                                lineHeight: 1
                                }}
                            />:null
            }}
        ></GenericModal>
    )
} 