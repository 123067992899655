import axios from "../services/axiosService.js";
import CommonService from "./commonService.js";

class MemberService {
  commonService = new CommonService();
  constructor() {
    this.apiFolder = process.env.REACT_APP_WEB_API_FOLDER;
  }
  //  Api call to Get all the members based on a projectId
  //  Input: Project UUID
  //  OutPut: Members added to the project
  GetProjectDetailById = async (ProjectiId = String) => {
    try {
      const vApiEndpoint = this.commonService.getAPIUrl();
      return await axios
        .get(
          vApiEndpoint + this.apiFolder + "/Member/GetMembersDetailsByProject?project_UUID=" + ProjectiId + "",
          {
            headers: {
              "Content-type": "application/json; charset=UTF-8",
              Accept: "application/json",
            },
          }
        )
        .then((data) => {
          const datareturn = data?.data;
          return datareturn;
        });
    } catch (error) {
      this.commonService.logException(
        error,
        "Member Component",
        "GetMembersDetailsByProject"
      );

    }
  };
}

export default MemberService;
