/**
 * Upload Question & Answer Modal
 * Created On: 16-08-2024
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
//  Motif controls
import MotifModal, { MotifModalHeader, MotifModalBody, MotifModalFooter } from '@ey-xd/motif-react/Modal';
import MotifButton from '@ey-xd/motif-react/Button';
import MotifFormField from '@ey-xd/motif-react/FormField';
import { MotifFileUploader, MotifFileUploaderItem, MotifIcon, MotifTooltip } from '@ey-xd/motif-react';
import DocumentService from '../../../../services/documentService';
import CCWarning from '../../../../assets/images/uploadqawarning.svg'
import iconFileUpload from '../../../../assets/images/file-upload.svg'
import iconProgressStep from '../../../../assets/images/progress_step.svg';
import './UploadQA.scss';

const UploadQA = ({
    openModal,
    setOpenModal,
    userId,
    projectId,
    tableId,
    exportedFileName,
    exportImportDetails,
    setOpenModalForUploadProgress
}) => {
    let invalidTypeMsg = "Invalid file type. Select a different file. \r\nxlsm (max. 5MB)";

    const [displayUploadQAFileError, setDisplayUploadQAFileError] = useState(false);
    const [displayUploadQAFileOverSizeError, setDisplayUploadQAFileOverSizeError] = useState(false);
    const [displayUploadQAFileNameError, setDisplayUploadQAFileNameError] = useState(false);
    const [displayUploadQAProcess, setDisplayUploadQAProcess] = useState(false);
    const [displayUploadQADone, setDisplayUploadQADone] = useState(false);
    const [displayUploadQAServerError, setDisplayUploadQAServerError] = useState(false);
    const [displayUploadQATechError, setDisplayUploadQATechError] = useState(false);
    const [displayAbortQA, setDisplayAbortQA] = useState(false);
    const [backEndError, setBackEndError] = useState("");
    const [fileVersionError, setFileVersionError] = useState("");
    const [uploadedFileName, setUploadedFileName] = useState("");
    const [uploadedFileSize, setUploadedFileSize] = useState(0);
    const [openCCModal, setOpenCCModal] = useState(false);
    const [openAQAModal, setOpenAQAModal] = useState(false);
    const [isUpdateBtnDisabled, setIsUpdateBtnDisabled] = useState(true);
    const [isUpdateBtnTooltipDisabled, setIsUpdateBtnTooltipDisabled] = useState(true);
    const documentService = new DocumentService();
    let uploadQAFileSizeLimit = 5242880;

    const uploadQADocuments = async (file) => {
        let response = await documentService.uploadQADoc(userId, projectId, tableId, file);
        if (response) {
            setDisplayUploadQAProcess(false);
            if (response.data) {
                if (response.data.Validation) {
                    //Success from Back End
                    setDisplayUploadQADone(true);
                    setIsUpdateBtnDisabled(false);
                    setIsUpdateBtnTooltipDisabled(false);
                    setDisplayAbortQA(true);
                }
                else {
                    //Error from Back End
                    setBackEndError(response.data.Message);
                    setDisplayUploadQAServerError(true);
                    setDisplayUploadQADone(false);
                    setIsUpdateBtnDisabled(true);
                }
            }
            else {
                //Technical Errors
                setDisplayUploadQATechError(true);
                setIsUpdateBtnDisabled(true);
            }

        }
        else {
            setDisplayUploadQAProcess(false);
            //Technical Errors
            setDisplayUploadQATechError(true);
        }
    };

    const cancelUploadQADoc = async () => {
        let response = await documentService.cancelUploadQADoc(userId, projectId, tableId);
        if (response) {
            if (openCCModal) {
                setOpenCCModal(false);
            }
            if (openAQAModal) {
                setOpenAQAModal(false);
   
            }
            setIsUpdateBtnDisabled(true);
            reset();
        }
    };

    const handleContinueBtnClick = () => {
        setOpenModalForUploadProgress(true);
    }

    const reset = () => {
        setDisplayUploadQAFileError(false);
        setDisplayUploadQAFileNameError(false);
        setDisplayUploadQAFileOverSizeError(false);
        setDisplayUploadQAProcess(false);
        setDisplayUploadQADone(false);
        setDisplayUploadQATechError(false);
        setDisplayUploadQAServerError(false);
        setIsUpdateBtnTooltipDisabled(true);
        setDisplayAbortQA(false);
    }

    const handleFileUpload = (event) => {
        let validateFlag = true;
        reset();

        const allowedTypes = [
            "application/vnd.ms-excel.sheet.macroEnabled.12"
        ];

        const chosenFiles = Array.prototype.slice.call(event);
        chosenFiles.some((file) => {

            if (!allowedTypes.includes(file?.type)) {

                validateFlag = false;
                file.error = "Incorrect File Format ";
                setDisplayUploadQAFileError(true);
            }
            if (validateFlag && file.size > uploadQAFileSizeLimit) {
                validateFlag = false;
                setDisplayUploadQAFileOverSizeError(true);
            }
            if (validateFlag && file.name !== exportedFileName) {
                validateFlag = false;
                validateFileName(file.name, exportedFileName);
                setDisplayUploadQAFileNameError(true);
            }
            if (validateFlag) {
                file.error = "";
                setUploadedFileName(file.name);
                setUploadedFileSize(file.size);
                setDisplayUploadQAProcess(true);
                uploadQADocuments(file);  
            }
            return null;
        });
    };

    const validateFileName = (uploadedFileName, exportedFileName) => {
        let invalidTemplatemsg = "Invalid file template. Select a different file. \r\nxlsm (max. 5MB)";
        if(!uploadedFileName.includes('QnA_V'))
        {
            setFileVersionError(invalidTemplatemsg);
        }
        else
        {
            if(exportedFileName !== null)
            {
                let tempUploadfile = uploadedFileName.split('QnA_V')[0];
                let tempExportfile = exportedFileName.split('QnA_V')[0];
                if(tempUploadfile === tempExportfile)
                {
                    validateFileVersion();
                }
                else
                {
                    setFileVersionError(invalidTemplatemsg);
                }
            }
        }

    }

    const validateFileVersion = () => {
        let months = {
            '01': 'January',
            '02': 'February',
            '03': 'March',
            '04': 'April',
            '05': 'May',
            '06': 'June',
            '07': 'July',
            '08': 'August',
            '09': 'September',
            '10': 'October',
            '11': 'November',
            '12': 'December'
        };
        let exportDate = exportImportDetails.ExportDate != null ? exportImportDetails.ExportDate : "";
        exportDate = exportDate.split(' ')[0];
        let filevermsg = "The Q&A file is not latest version, Upload " +
            exportImportDetails.FileName +
            " exported by " +
            exportImportDetails.UserID +
            " on " +
            exportDate.split('-')[2] +
            " " +
            months[exportDate.split('-')[1]] +
            " " +
            exportDate.split('-')[0] +
            " and continue.";
        setFileVersionError(filevermsg);

    }

    return (
        <div>
            <MotifModal
                show={setOpenModal}
                onClose={() => {
                    if (displayUploadQAProcess) {
                        setOpenCCModal(!openCCModal);
                    }
                    else if (displayAbortQA) {
                        setOpenAQAModal(!openAQAModal);
                    }
                    else {
                        setOpenModal(!openModal);
                    }
                }}
                className='uploadQAModal genericModal startIconClose modalMotifFooterModal'
                size='xl'
            >
                <MotifModalHeader
                    closeButtonProps={{
                        'aria-label': 'Close',
                        title: 'Close'
                    }}
                    className='uploadQAHeader'
                >
                    <div className='uploadQAHead'>Upload Q&A</div>
                </MotifModalHeader>
                <MotifModalBody className='uploadQAModalBody'>
                    <div className='lblSelectRange'>
                        The Questions & Answers in the file uploaded will replace the current ones.
                    </div>
                    <MotifFormField>
                        <MotifFileUploader
                            className="uploaderdiv"
                            id="my-id"
                            label="Click to upload or drag and drop xlsm (max. 5 MB)"
                            multiple={false}
                            onDrop={handleFileUpload}

                        >
                            {displayUploadQAProcess &&
                                <MotifFileUploaderItem
                                    className='uploadInProc'
                                    aria-label="Item aria-label text"
                                    fileIcon={<img className='motif-icon' src={iconFileUpload} alt='Upload' />}
                                    fileName={uploadedFileName}
                                    fileSize={10000}
                                    id="1"
                                    isIndeterminate
                                    onRemove={function Ua() { }}
                                    percentUploaded={20}
                                    removable
                                    showProgress
                                />
                            }
                            {displayUploadQAFileError &&
                                <MotifFileUploaderItem
                                    aria-label="Item aria-label text"
                                    error
                                    errorText="custom error message"
                                    fileIcon={<MotifIcon src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTQgMkg2Yy0xLjEgMC0xLjk5LjktMS45OSAyTDQgMjBjMCAxLjEuODkgMiAxLjk5IDJIMThjMS4xIDAgMi0uOSAyLTJWOGwtNi02em0yIDE2SDh2LTJoOHYyem0wLTRIOHYtMmg4djJ6bS0zLTVWMy41TDE4LjUgOUgxM3oiLz48L3N2Zz4=" />}
                                    fileName={invalidTypeMsg}
                                    fileSize={10000}
                                    id="2"
                                    onRemove={function Ua() { }}
                                    removable
                                />
                            }
                            {displayUploadQAFileOverSizeError &&
                                <MotifFileUploaderItem
                                    aria-label="Item aria-label text"
                                    error
                                    errorText="custom error message"
                                    fileIcon={<MotifIcon src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTQgMkg2Yy0xLjEgMC0xLjk5LjktMS45OSAyTDQgMjBjMCAxLjEuODkgMiAxLjk5IDJIMThjMS4xIDAgMi0uOSAyLTJWOGwtNi02em0yIDE2SDh2LTJoOHYyem0wLTRIOHYtMmg4djJ6bS0zLTVWMy41TDE4LjUgOUgxM3oiLz48L3N2Zz4=" />}
                                    fileName={invalidTypeMsg}
                                    fileSize={10000}
                                    id="3"
                                    onRemove={function Ua() { }}
                                    removable
                                />
                            }
                            {displayUploadQAFileNameError &&
                                <MotifFileUploaderItem
                                    aria-label="Item aria-label text"
                                    error
                                    errorText="custom error message"
                                    fileIcon={<MotifIcon src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTQgMkg2Yy0xLjEgMC0xLjk5LjktMS45OSAyTDQgMjBjMCAxLjEuODkgMiAxLjk5IDJIMThjMS4xIDAgMi0uOSAyLTJWOGwtNi02em0yIDE2SDh2LTJoOHYyem0wLTRIOHYtMmg4djJ6bS0zLTVWMy41TDE4LjUgOUgxM3oiLz48L3N2Zz4=" />}
                                    fileName={fileVersionError}
                                    fileSize={10000}
                                    id="4"
                                    onRemove={function Ua() {  setDisplayUploadQAFileNameError(false)}}
                                    removable
                                />
                            }
                            {displayUploadQAServerError &&
                                <MotifFileUploaderItem
                                    aria-label="Item aria-label text"
                                    error
                                    errorText="custom error message"
                                    fileIcon={<MotifIcon src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTQgMkg2Yy0xLjEgMC0xLjk5LjktMS45OSAyTDQgMjBjMCAxLjEuODkgMiAxLjk5IDJIMThjMS4xIDAgMi0uOSAyLTJWOGwtNi02em0yIDE2SDh2LTJoOHYyem0wLTRIOHYtMmg4djJ6bS0zLTVWMy41TDE4LjUgOUgxM3oiLz48L3N2Zz4=" />}
                                    fileName={backEndError}
                                    fileSize={10000}
                                    id="5"
                                    onRemove={function Ua() { }}
                                    removable
                                />
                            }
                            {displayUploadQATechError &&
                                <MotifFileUploaderItem
                                    aria-label="Item aria-label text"
                                    error
                                    errorText="custom error message"
                                    fileIcon={<MotifIcon src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTQgMkg2Yy0xLjEgMC0xLjk5LjktMS45OSAyTDQgMjBjMCAxLjEuODkgMiAxLjk5IDJIMThjMS4xIDAgMi0uOSAyLTJWOGwtNi02em0yIDE2SDh2LTJoOHYyem0wLTRIOHYtMmg4djJ6bS0zLTVWMy41TDE4LjUgOUgxM3oiLz48L3N2Zz4=" />}
                                    fileName={uploadedFileName + " Upload failed due to a technical error. Try again."}
                                    fileSize={10000}
                                    id="6"
                                    onRemove={function Ua() { setIsUpdateBtnDisabled(true)}}
                                    removable
                                />
                            }
                            {displayUploadQADone &&
                                <MotifFileUploaderItem
                                    className='uploadDone'
                                    aria-label="Item aria-label text"
                                    fileIcon={<img className='motif-icon' src={iconFileUpload} alt='Upload' />}
                                    fileName={uploadedFileName}
                                    fileSize={uploadedFileSize}
                                    id="7"
                                    percentUploaded={100}
                                    removable={false}
                                    showProgress
                                    customButton={<img className='motif-icon iconProgressStep' src={iconProgressStep} alt='Upload' />}
                                />
                            }
                        </MotifFileUploader>
                    </MotifFormField>
                </MotifModalBody>
                <MotifModalFooter className='uploadQAFooter'>
                    <MotifButton
                        onClick={() => {
                            if (displayUploadQAProcess) {
                                setOpenCCModal(!openCCModal);
                            }
                            else if (displayAbortQA) {
                                setOpenAQAModal(!openAQAModal);
                            }
                            else {
                                setOpenModal(!openModal);
                            }
                        }
                        }
                        type="button"
                        variant="secondary"
                        className='btnCancel'
                    >
                        Cancel
                    </MotifButton>
                    <MotifTooltip
                        hide={isUpdateBtnTooltipDisabled}
                        trigger=
                        {
                            <MotifButton className='btnNext'
                                disabled={isUpdateBtnDisabled}//{isUpdateBtnDisabled}
                                onClick={handleContinueBtnClick}//() => { updateQADoc(); }
                            >
                                Continue
                            </MotifButton>
                        }
                        contentClassName='tooltipWithoutClose'
                        allowHover={true}
                        allowClick={false}
                        hideCloseButton={true}
                    >
                        Update Q&A and generate line analysis
                    </MotifTooltip>
                </MotifModalFooter>
            </MotifModal>
            <MotifModal
                show={openCCModal}
                onClose={() => setOpenCCModal(!openCCModal)}
                className='uploadQACCModal genericModal startIconClose modalMotifFooterModal'
                size='sm'
            >
                <MotifModalHeader
                    closeButtonProps={{
                        'aria-label': 'Close',
                        title: 'Close'
                    }}
                    className='uploadQACCHeader'
                >
                    <div className='uploadQACCHead'>
                        <img src={CCWarning} alt='Warning' />
                    </div>
                </MotifModalHeader>
                <MotifModalBody className='uploadQACCModalBody'>
                    <div className='uploadQACCHead'>
                        Discard File Upload
                    </div>
                    <div className='lblSelectRange'>
                        Confirm you would like to stop the upload process and discard the selected file.
                    </div>
                </MotifModalBody>
                <MotifModalFooter className='uploadQACCFooter'>
                    <MotifButton
                        onClick={() => {
                            setOpenCCModal(!openCCModal);
                        }
                        }
                        type="button"
                        variant="secondary"
                        className='btnCancel'
                    >
                        Cancel
                    </MotifButton>
                    <MotifButton className='btnNext'
                        onClick={() => {
                            cancelUploadQADoc();
                        }
                        }
                    >
                        Confirm
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>
            <MotifModal
                show={openAQAModal}
                onClose={() => setOpenAQAModal(!openAQAModal)}
                className='uploadQACCModal genericModal startIconClose modalMotifFooterModal'
                size='sm'
            >
                <MotifModalHeader
                    closeButtonProps={{
                        'aria-label': 'Close',
                        title: 'Close'
                    }}
                    className='uploadQACCHeader'
                >
                    <div className='uploadQACCHead'>
                        <img src={CCWarning} alt='Warning' />
                    </div>
                </MotifModalHeader>
                <MotifModalBody className='uploadQACCModalBody'>
                    <div className='uploadQACCHead'>
                        Abort Q&A Upload
                    </div>
                    <div className='lblSelectRange'>
                        Confirm you would like to abort the Q&A Upload process
                    </div>
                </MotifModalBody>
                <MotifModalFooter className='uploadQACCFooter'>
                    <MotifButton
                        onClick={() => {
                            setOpenAQAModal(!openAQAModal);
                        }
                        }
                        type="button"
                        variant="secondary"
                        className='btnCancel'
                    >
                        Cancel
                    </MotifButton>
                    <MotifButton className='btnNext'
                        onClick={() => {
                            cancelUploadQADoc();
                        }
                        }
                    >
                        Confirm
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>
        </div>
    );
};
UploadQA.propTypes = {
    openModal: PropTypes.bool,
    setOpenModal: PropTypes.func,
    userId: PropTypes.string,
    projectId: PropTypes.string,
    tableId: PropTypes.string,
    exportedFileName: PropTypes.string,
    setShowNotification: PropTypes.func,
    setNotificationMessage: PropTypes.func,
    setNotificationType: PropTypes.func,
    exportImportDetails: PropTypes.object,
    receiveUploadQnAStatus: PropTypes.func
};
export default UploadQA;