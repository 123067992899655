/**
 * Common Service class for common functions
 * Modified on 09-08-2024
 */

import { appInsights } from "./appInsights.js";
import axios from './axiosService.js';
import store from "../store";

import tableTypesData from '../assets/data/tableTypes.json';

class CommonService {
  constructor() {

    this.apiHostEnv = process.env.REACT_APP_HOSTING_ENV;

    this.webApiEndpoint = process.env.REACT_APP_WEB_API_ENDPOINT;

    this.webApiEndpointAU = process.env.REACT_APP_WEB_API_ENDPOINT_AU;
    this.webApiEndpointEU = process.env.REACT_APP_WEB_API_ENDPOINT_EU;
    this.webApiEndpointUS = process.env.REACT_APP_WEB_API_ENDPOINT_US;

    this.platformApiPointer = process.env.REACT_APP_PLATFORM_API_POINTER;
    this.platformApiFolder = process.env.REACT_APP_PLATFORM_API_FOLDER;

    this.platformApiPollingUrl = process.env.REACT_APP_PLATFORM_API_POLLING_URL;
    this.platformApiPollingUrlAU = process.env.REACT_APP_PLATFORM_API_POLLING_URL_AU;
    this.platformApiPollingUrlEU = process.env.REACT_APP_PLATFORM_API_POLLING_URL_EU;
    this.platformApiPollingUrlUS = process.env.REACT_APP_PLATFORM_API_POLLING_URL_US;

    this.platformApiAnalysisUrl = process.env.REACT_APP_PLATFORM_API_ANALYSIS_URL;
    this.platformApiAnalysisUrlAU = process.env.REACT_APP_PLATFORM_API_ANALYSIS_URL_AU;
    this.platformApiAnalysisUrlEU = process.env.REACT_APP_PLATFORM_API_ANALYSIS_URL_EU;
    this.platformApiAnalysisUrlUS = process.env.REACT_APP_PLATFORM_API_ANALYSIS_URL_US;

    //  Headers
    this.headers = { "Content-type": "application/json; charset=UTF-8" };

    //  API url methods
    this.apiUrl = {
      getReferences: '/getGroupKey'
    }

    //  List of API endpoints to point to local mock data
    this.mockApiUrl = [
      // 
    ]
  }
  logException(error, componentName, methodName, requestObject=null, userId=null) {
    appInsights.trackException({
      error: error,
      properties: {
        Component: componentName,
        Method: methodName,
        UserId: userId,
        payload: requestObject,
        errorData:error?.response?.data
      },
    });
  }

  /**
   * Function to get Table type references
   * @returns 
   */
  async getReferences() {
      if (this.platformApiPointer.toLowerCase() === 'local') {
          return Promise.resolve(tableTypesData);
      } else {
          const vUrl = this.webApiEndpoint + this.platformApiFolder + this.apiUrl.getReferences;
          const vParams = {
              method: "get",
              url: vUrl,
              headers: this.headers,
              data: JSON.stringify({})
          };
          const response = await axios.request(vParams).catch((error) => {
              this.commonService.logException(
                  error,
                  "Common Service",
                  "Get References"
              );
          });
          return response?.data;
      }
  }

  getAPIUrl() {
    const vDataHostingLocation = store.getState().user?.activeProject?.dataHostingLocation;
    if (this.apiHostEnv === "UAT" || this.apiHostEnv === "PROD") {
      if (vDataHostingLocation === '1') {
        return this.webApiEndpointUS;
      } else if (vDataHostingLocation === '2') {
        return this.webApiEndpointEU;
      } else {
        return this.webApiEndpointAU;
      }
    } else {
      return this.webApiEndpoint;
    }
  }

  getAPIPollingUrl() {
    const vDataHostingLocation = store.getState().user?.activeProject?.dataHostingLocation;
    if (this.apiHostEnv === "UAT" || this.apiHostEnv === "PROD") {
      if (vDataHostingLocation === '1') {
        return this.platformApiPollingUrlUS;
      } else if (vDataHostingLocation === '2') {
        return this.platformApiPollingUrlEU;
      } else {
        return this.platformApiPollingUrlAU;
      }
    } else {
      return this.platformApiPollingUrl;
    }
  }

  getAPIAnalysisUrl() {
    const vDataHostingLocation = store.getState().user?.activeProject?.dataHostingLocation;
    if (this.apiHostEnv === "UAT" || this.apiHostEnv === "PROD") {
      if (vDataHostingLocation === '1') {
        return this.platformApiAnalysisUrlUS;
      } else if (vDataHostingLocation === '2') {
        return this.platformApiAnalysisUrlEU;
      } else {
        return this.platformApiAnalysisUrlAU;
      }
    } else {
      return this.platformApiAnalysisUrl;
    }
  }
}
export default CommonService;
