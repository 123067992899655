import axios from "../services/axiosService.js";
import CommonService from "../services/commonService.js";

class DocumentService {
  commonService = new CommonService();
  constructor() {
    this.webApiFolder = process.env.REACT_APP_WEB_API_FOLDER;
    this.platformApiAnalysisFolder = process.env.REACT_APP_PLATFORM_API_ANALYSIS_FOLDER;
  }

  async getDocuments(projectId) {
    const userDocumentRequest = {
      Project_UUID: projectId,
    };
    const vApiEndpoint = this.commonService.getAPIUrl();
    const response = await axios
      .get(vApiEndpoint + this.webApiFolder + "/Document/GetDocumentDetails", {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        params: userDocumentRequest,
      })
      .catch((error) => {
        this.commonService.logException(
          error,
          "Get Documents"
        );
      });

    return response?.data;
  }

  async uploadQADoc(userId, projectId, tableId, file) {
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("projectId", projectId);
    formData.append("tableId", tableId);
    formData.append("filename", file.name);
    formData.append("file", file);
    const vApiEndpoint = this.commonService.getAPIUrl();
    const url = vApiEndpoint + this.platformApiAnalysisFolder + "/import_qna";
    const response = await axios
      .post(url, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      })
      .catch((error) => {
        this.commonService.logException(
          error,
          "uploadQADoc",
          "Upload QA Document"
        );
        return {
          status: 500,
        };
      });

    return response;
  }

  async cancelUploadQADoc(userId, projectId, tableId) {

    const requestPayload = {
      projectId: projectId,
      tableId: tableId,
      userId: userId
    };
    const vApiEndpoint = this.commonService.getAPIUrl();
    const url = vApiEndpoint + this.platformApiAnalysisFolder + "/cancel_import_details";
    try {
      return await axios.post(url, requestPayload).then((data) => {
        const returnData = data?.data;
        return returnData;
      });
    } catch (error) {
      this.commonService.logException(
        error,
        "cancelUploadQADoc",
        "Cancel Upload QA Document"
      );
      return {
        status: 500,
      };

    }


  }

  async updateQADoc(data) {
    const vApiEndpoint = this.commonService.getAPIUrl();
    const url = vApiEndpoint + this.platformApiAnalysisFolder + "/update_import";
    try {
      return await axios.post(url, data).then((data) => {
        const returnData = data?.data;
        return returnData;
      });
    } catch (error) {
      this.commonService.logException(
        error,
        "updateQADoc",
        "Update QA"
      );
      return {
        status: 500,
      };

    }
  }

}

export default DocumentService