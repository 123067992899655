import { MotifModal, MotifModalBody, MotifModalFooter, MotifModalHeader, MotifButton } from '@ey-xd/motif-react';
import PropTypes from 'prop-types';
import iconWarning from "../../../../assets/images/warning.svg";
import "./DeletePrompt.scss";

function DeletePrompt({prompt, showDeleteModal, handleCloseDeletePromptModal, handleConfirmDeletePrompt}) {

    return (
        <MotifModal id="deletePromptModal" 
            show={showDeleteModal} 
            size="sm"
            focusTrapOptions={{
                tabbableOptions: {
                    displayCheck: 'none',
                },
            }}
            onClose={handleCloseDeletePromptModal}
            className="deletePromptModal confirmModal startIconClose modalNoHeaderBorder modalMotifFooterModal"
        >
            <MotifModalHeader
                closeButtonProps={{
                    'aria-label': 'Close',
                    title: 'Close'
                }}
                
            >
                <img src={iconWarning} alt="Warning icon" />
                <div>Delete Prompt</div>
            </MotifModalHeader>
            <MotifModalBody>
                <span>
                    Confirm you would like to delete the prompt <br/>
                    '{prompt.promptName}'?
                </span>
            </MotifModalBody>
            <MotifModalFooter>
                <MotifButton
                    label="btnCancel"
                    size="small"
                    type="button"
                    variant="secondary"
                    className="btnCancel"
                    onClick={handleCloseDeletePromptModal}
                >
                    Cancel
                </MotifButton>
                <MotifButton
                    label="btnConfirm"
                    size="small"
                    type="button"
                    variant="primary"
                    className="btnConfirm"
                    onClick={handleConfirmDeletePrompt}
                >
                    Confirm
                </MotifButton>
            </MotifModalFooter>
        </MotifModal>
    );
}

DeletePrompt.propTypes = {
    promptName: PropTypes.object,
    showDeleteModal: PropTypes.bool,
    handleCloseDeletePromptModal: PropTypes.func,
    handleConfirmDeletePrompt: PropTypes.func
}

export default DeletePrompt;