/**
 * Component to provide settings for line and table analysis
 * 1. Prompt selection as personal or central
 * 2. Q&A option
 * 3. Full table context option
 * 4. Documents option
 * Created on 10/09/2024
 */
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";

//  Motif controls
import MotifModal, { MotifModalHeader, MotifModalBody, MotifModalFooter } from "@ey-xd/motif-react/Modal";
import MotifButton from "@ey-xd/motif-react/Button";
import { MotifCheckbox, MotifLabel, MotifToast, MotifTooltip, MotifMessage } from "@ey-xd/motif-react";
import MotifFormField from '@ey-xd/motif-react/FormField';
import MotifRadioButton, { MotifRadioButtonGroup } from '@ey-xd/motif-react/RadioButton';
import MotifSelect, { MotifOption } from '@ey-xd/motif-react/Select';
import MotifTabNavigation, { MotifTabControl } from "@ey-xd/motif-react/TabNavigation";

//  Internal imports
import "./AnalysisSettings.scss";
import DocumentService from "../../../../services/documentService";
import iconInfo from "../../../../assets/images/information.svg";
import iconWarning from "../../../../assets/images/warning-triangle.svg";
import AnalysisService from "../../../../services/analysisService";

const AnalysisSettings = ({
  initSource,
  openModal,
  setOpenModal,
  fromUploadProcess,
  setOpenModalForUploadProgress,
  closeModal,
  onConfirm,
  lineAnalysisStatus,
  documentList,
  isCallForTableAnalysis,
  selectedTable,
  exportedFileName,
  receiveUploadQnAStatus,
  setDisplayUploadQAModal,
  setUploadQALoader
}) => {
  const promptTypeRef = [
    {
      "id": 1,
      "key": "Central",
      "value": "Central",
      "label": "Central"
    },
    {
      "id": 2,
      "key": "Personal",
      "value": "Personal",
      "label": "Personal"
    }
  ]
  const vTabs = [
    {
      id: 1,
      elementId: "idTabContentActive",
      title: "Active",
    },
    {
      id: 2,
      elementId: "idTabContentDeleted",
      title: "Deleted",
    },
  ];
  const vTabIds = {
    active: "idTabContentActive",
    deleted: "idTabContentDeleted"
  };
  const [selectedPromptType, setSelectedPromptType] = useState('Central');
  const [analysisSettings, setAnalysisSettings] = useState(null);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [isMultiplePrompts, setIsMultiplePrompts] = useState(false);
  const [useQnA, setUseQnA] = useState(true);
  const [fullTableContext, setFullTableContext] = useState(false);
  const [excelOutput, setExcelOutput] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [delDocuments, setDelDocuments] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [isFullTableContextDisabled, setIsFullTableContextDisabled] = useState(false);

  const [currentStep, setCurrentStep] = useState(1);
  const [showMessage, setShowMessage] = useState(false);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true);
  const [isCheckBoxDisabled, setIsCheckBoxDisabled] = useState(false);

  const documentService = new DocumentService();
  const analysisService = new AnalysisService();

  const { user, activeProject } = useSelector((state) => state.user);
  const userEmailId = user.username;
  const selectedProjectId = activeProject.project_UUID;

  /**
   * This asynchronous function retrieves document data from the `documentService` using
   * the `selectedProjectId` provided in `props`. Once the data is fetched, it updates the `documents`
   * state with the retrieved data.
   */
  const getDocuments = async () => {
    const docData = await documentService.getDocuments(selectedProjectId);
    const vActiveDocs = docData.filter(element => (element.status === 'Complete'));
    const vDeletedDocs = docData.filter(element => (element.status === 'Deleted'));
    setDocuments(vActiveDocs);
    setDelDocuments(vDeletedDocs);
    populateSelectedDocuments(vActiveDocs);
  };

  const populateSelectedDocuments = (vDocs) => {
    if (documentList && (typeof (documentList) !== 'string') && (documentList.length > 0)) {
      let tmpDocs = [];
      documentList.forEach(element => {
        let vDoc;
        if (typeof (element) === 'string') {
          vDoc = vDocs.find(ele => ((ele.documentName === element) && (ele.status === 'Complete')));
        } else if (typeof (element) === 'object') {
          vDoc = vDocs.find(ele => (((ele.document_UUID === element.id) || (ele.documentId === element.id)) && (ele.status === 'Complete')));
        }
        if (vDoc) {
          tmpDocs.push(vDoc);
        }
      });
      setSelectedDocuments(tmpDocs);
    }
  };

  useEffect(() => {
    if (!isCallForTableAnalysis) {
      if (lineAnalysisStatus === "In-Progress") {
        setIsNextButtonDisabled(false);
        setIsConfirmDisabled(false);
        setIsCheckBoxDisabled(true);
        setShowMessage(true);
      } else if (
        lineAnalysisStatus === "Completed" ||
        lineAnalysisStatus === "No Analysis Initiated"
      ) {
        setIsNextButtonDisabled(false);
        setIsConfirmDisabled(false);
        setIsCheckBoxDisabled(false);
      } else {
        setIsNextButtonDisabled(true);
        setIsConfirmDisabled(true);
      }
    } else {
      setCurrentStep(2);
      setIsConfirmDisabled(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isCallForTableAnalysis) {
      getDocuments();
      if (selectedDocuments.length > 0) {
        if (documents.length === selectedDocuments.length) {
          setShowMessage(true);
        } else {
          setShowMessage(false);
        }
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isCallForTableAnalysis) {
      getAnalysisSettings()
    }
    // eslint-disable-next-line
  }, []);

  const getAnalysisSettings = () => {
    let vProjectCategory = "";
    if (activeProject?.category === '1') {
      vProjectCategory = "client";
    } else if (activeProject?.category === "2") {
      vProjectCategory = "testing";
    }
    const vData = {
      "userId": userEmailId,
      "projectId": selectedProjectId,
      "tableId": selectedTable.id,
      "tableType": selectedTable.tableType,
      "projectCategory": vProjectCategory
    };
    analysisService.getAnalysisSettings(vData).then(response => {
      if (response) {
        setAnalysisSettings(response);
        if (response?.centralPrompt[0]) {
          setSelectedPrompt(response?.centralPrompt[0]);
          if (!response?.centralPrompt[0]?.disable) {
            setFullTableContext(true);
            setIsFullTableContextDisabled(true);
          }
        }
      }
    });
  }

  /**
   * This function maintain state for selected documents from list
   * @param {*} e : Click event
   */
  const handleDocumentSelectList = (vDoc) => {
    let vDocs;
    if (selectedDocuments && (selectedDocuments.length > 0)) {
      const docIndex = selectedDocuments.findIndex(element => (element.document_UUID === vDoc.document_UUID));
      if (docIndex !== -1) {
        vDocs = selectedDocuments.filter(element => (element.document_UUID !== vDoc.document_UUID));
      } else {
        vDocs = [...selectedDocuments, vDoc];
      }
    } else {
      vDocs = [...selectedDocuments, vDoc];
    }
    if (vDocs.length > 0) {
      if (documents.length === vDocs.length) {
        setShowMessage(true);
      } else {
        setShowMessage(false);
      }
    }
    setSelectedDocuments(vDocs);
  };

  /**
   * Handles the Next button click
   */
  const handleNextClick = () => {
    setCurrentStep(2);
  };

  /**
   * Handles the Back button click
   */
  const handleBackClick = () => {
    setCurrentStep(1);
  };

  /**
   * This function mainstain the state check box of line commentary ib excel
   */
  const onExcelOutputChange = (e) => {
    setExcelOutput(!excelOutput);
  };

  /**
   * Handles the close button click of the popup
   */
  const onToastcloseclick = () => {
    setShowMessage(false);
  };

  /**
   * Confirm button click, sends the selected document data and Line Comeentary Excel check box value
   */
  const handleConfirmClick = () => {
    let data;
    if (!isCallForTableAnalysis) {
      data = {
        promptIds: {
          userPromptId: selectedPrompt.id,
          systemPromptId: analysisSettings?.systemPrompt[0] ? analysisSettings?.systemPrompt[0] : ''
        },
        qnaFlag: useQnA,
        fulltableFlag: fullTableContext,
        isCommentaryExcel: excelOutput,
        documents: selectedDocuments,
      };
    } else {
      data = {
        isCommentaryExcel: excelOutput
      };
    }
    onConfirm(data);
  };

  const handleConfirmForUploadQANFlow = () => {
    updateQADocAndAnalysisSettings()
  }
 
  const onPromptTypeChange = (vValue) => {
    setSelectedPromptType(vValue);
    if (vValue === 'Central') {
      setSelectedPrompt(analysisSettings?.centralPrompt[0]);
      setIsMultiplePrompts(false);
      if (!analysisSettings?.centralPrompt[0]?.disable) {
        setFullTableContext(true);
        setIsFullTableContextDisabled(true);
      } else {
        setFullTableContext(false);
        setIsFullTableContextDisabled(false);
      }
    } else if ((vValue === 'Personal') && (analysisSettings?.personalPrompt.length > 1)) {
      setIsMultiplePrompts(true);
      setSelectedPrompt(null);
      setFullTableContext(false);
      setIsFullTableContextDisabled(false);
    } else if ((vValue === 'Personal') && (analysisSettings?.personalPrompt.length < 2)) {
      setIsMultiplePrompts(false);
      setSelectedPrompt(analysisSettings?.personalPrompt[0]);
      if (!analysisSettings?.personalPrompt[0]?.disable) {
        setFullTableContext(true);
        setIsFullTableContextDisabled(true);
      } else {
        setFullTableContext(false);
        setIsFullTableContextDisabled(false);
      }
    }
  };

  const onUseQnAChange = (e) => {
    setUseQnA(e.target.checked);
  }

  const onFullTableContextChange = (e) => {
    setFullTableContext(e.target.checked);
  }

  const onPromptNameChange = (data) => {
    setSelectedPrompt(data);
    if (!data?.disable) {
      setFullTableContext(true);
      setIsFullTableContextDisabled(true);
    } else {
      setFullTableContext(false);
      setIsFullTableContextDisabled(false);
    }
  }

  /**
   * Function to switch tabs
   * @param {*} tab
   */
  const handleTabClick = (tab) => {
    switch (tab.elementId) {
      case vTabIds.active:
        document.getElementById(vTabIds.active).style.display = "block";
        document.getElementById(vTabIds.deleted).style.display = "none";
        break;
      case vTabIds.deleted:
        document.getElementById(vTabIds.active).style.display = "none";
        document.getElementById(vTabIds.deleted).style.display = "block";
        break;
      default:
    }
  };

  const getModalTitle = () => {
    let vModalTitle = 'Select output';
    if (currentStep === 1) {
      vModalTitle = 'Analysis Settings';
    } else if ((currentStep === 2) && !isCallForTableAnalysis) {
      vModalTitle = 'Select documents (optional)';
    }
    return vModalTitle;
  };

  const updateQADocAndAnalysisSettings = async () => {
    const documentIdList = [];
    const documentList = [];
    selectedDocuments.forEach(document => {
      documentIdList.push({
        id: document.document_UUID,  //  documentId
        docName: document.documentName
      });
      documentList.push(document.documentName);
    });
    let data = {
      tableId:selectedTable.id,
      projectId:selectedProjectId,
      userId: userEmailId,
      filename: exportedFileName,
      qnaFlag: useQnA,
      fulltableFlag: fullTableContext,
      documentIdList: documentIdList,
      documentList: documentList,
      isCommentaryExcel: excelOutput,
      promptIds: {
        userPromptId: selectedPrompt.id,
        systemPromptId: analysisSettings?.systemPrompt[0] ? analysisSettings?.systemPrompt[0] : ''
      },
    };
    setOpenModalForUploadProgress(false);
    setDisplayUploadQAModal(false);
    setUploadQALoader(true);
    let response = await documentService.updateQADoc(data);
    if (response) {
      receiveUploadQnAStatus(response.status.toLowerCase());
    }
    else {
      receiveUploadQnAStatus(null);
    }
  };

  const cancelUploadQADoc = async () => {
    let response = await documentService.cancelUploadQADoc(user.username, activeProject.project_UUID, selectedTable.id);
    if (response) {
      if (fromUploadProcess) {
        setOpenModalForUploadProgress(false)
      }
    }
  }

  const onClose = () => {
    if (fromUploadProcess) {
      cancelUploadQADoc()
      setDisplayUploadQAModal(false);
    }
    else{
      setOpenModal(!openModal);
    }
  }

  return (
    <MotifModal
      show={openModal}
      onClose={onClose}
      focusTrapOptions={{
        tabbableOptions: {
          displayCheck: "none",
        },
      }}
      className="analysisSettingsModal genericModal modalMotifFooterModal"
    >
      <MotifModalHeader
        closeButtonProps={{
          "aria-label": "Close",
          title: "Close",
        }}
        className="analysisSettingsHeader"
      >
        <div className="analysisSettingsHead">{getModalTitle()}</div>
        {
          !isCallForTableAnalysis && <MotifLabel className="analysisSettingsSubHead">Step {currentStep} of 2</MotifLabel>
        }
      </MotifModalHeader>
      <MotifModalBody className="analysisSettingsBody">
        {(currentStep === 1) && !isCallForTableAnalysis && (
          <>
            <div className='fldPromptType'>
              <MotifFormField>
                <MotifLabel className='fldLabel'>
                  Choose Prompt Library
                </MotifLabel>
                <MotifRadioButtonGroup className="fldPromptTypeGroup">
                  {
                    promptTypeRef.map(element =>
                      <MotifRadioButton
                        id={"fldPromptType" + element.key}
                        name="fldPromptTypeOption"
                        onChange={() => onPromptTypeChange(element.value)}
                        value={element.value}
                        className="fldPromptTypeOption"
                        key={element.id}
                        checked={selectedPromptType === element.value}
                        disabled={(element.key === 'Personal') && (analysisSettings?.personalPrompt.length < 1)}
                      >
                        <span
                          className={(element.key === 'Personal') && (analysisSettings?.personalPrompt.length < 1) ? "promptTypeOptionValue" : ""}
                        >
                          {element.label}
                        </span>
                        {
                          (element.key === 'Personal') && (analysisSettings?.personalPrompt.length < 1) &&
                          <MotifTooltip
                            trigger={<img src={iconInfo} alt="info" className="iconInfo" />}
                            allowHover={true}
                            allowClick={false}
                            hideCloseButton={true}
                            placement="left"
                            contentClassName="tooltipWithoutClose"
                          >
                            No personal prompts available
                          </MotifTooltip>
                        }
                      </MotifRadioButton>
                    )
                  }
                </MotifRadioButtonGroup>
              </MotifFormField>
            </div>
            <div className='fldPromptName'>
              <MotifFormField>
                <MotifLabel className='fldLabel'>
                  {isMultiplePrompts ? 'Select Prompt' : 'Prompt Name'}
                </MotifLabel>
                {!isMultiplePrompts && <span className="fldValPromptName">{selectedPrompt?.promptName}</span>}
                {
                  isMultiplePrompts && (analysisSettings?.personalPrompt.length > 1) &&
                  <MotifSelect
                    visibleOptions={3}
                    className="fldPromptNameSelect"
                  >
                    {
                      analysisSettings.personalPrompt.map((element) =>
                        <MotifOption
                          onClick={() => onPromptNameChange(element)}
                          value={element?.promptName}
                          key={element.id}
                        >
                          {element.promptName}
                        </MotifOption>
                      )
                    }
                  </MotifSelect>
                }
              </MotifFormField>
            </div>
            <div className="divider"></div>
            <div className='fldUseQnA'>
              <MotifFormField>
                <MotifLabel className='fldLabel'>
                  Q&A
                </MotifLabel>
                <MotifCheckbox
                  checked={useQnA}
                  disabled={fromUploadProcess}
                  id="fldUseQnA"
                  name="fldUseQnA"
                  onChange={(e) => onUseQnAChange(e)}
                  value={useQnA}
                  className="fldValUseQnA"
                >
                  Use Q&A in analysis
                  {
                    fromUploadProcess &&
                    <MotifTooltip
                      trigger={<img src={iconInfo} alt="info" className="iconInfo" />}
                      allowHover={true}
                      allowClick={true}
                      hideCloseButton={true}
                      placement="left"
                      contentClassName="tooltipWithoutClose"
                    >
                      Q&A is selected by default as<br /> the process was initiated<br /> from the Upload Q&A option
                    </MotifTooltip>
                  }
                </MotifCheckbox>
                { (initSource === 'proceed') &&
                  <MotifMessage className="msgQA">
                    Selecting this option enables Q&A when generating Line Analysis. Where Q&A has previously been generated, the output will be replaced.
                  </MotifMessage>
                }
              </MotifFormField>
            </div>
            <div className='fldFullTableContext'>
              <MotifFormField>
                <MotifLabel className='fldLabel'>
                  Full Table Context
                </MotifLabel>
                <MotifCheckbox
                  checked={fullTableContext}
                  id="fldFullTableContext"
                  name="fldFullTableContext"
                  onChange={(e) => onFullTableContextChange(e)}
                  value={fullTableContext}
                  className="fldValFullTableContext"
                  disabled={isFullTableContextDisabled}
                >
                  Include full table as context
                  {
                    isFullTableContextDisabled &&
                    <MotifTooltip
                      trigger={<img src={iconInfo} alt="info" className="iconInfo" />}
                      allowHover={true}
                      allowClick={true}
                      hideCloseButton={true}
                      placement="left"
                      contentClassName="tooltipWithoutClose"
                    >
                      Full table context is included <br />in the prompt settings.
                    </MotifTooltip>
                  }
                </MotifCheckbox>
              </MotifFormField>
            </div>
            <div className='fldExcelOutput'>
              <MotifFormField>
                <MotifLabel className='fldLabel'>
                  Excel Output
                </MotifLabel>
                <MotifCheckbox
                  checked={excelOutput}
                  id="fldExcelOutput"
                  name="fldExcelOutput"
                  onChange={(e) => onExcelOutputChange(e)}
                  value={excelOutput}
                  className="fldValExcelOutput"
                >
                  Show line commentary in Excel
                </MotifCheckbox>
              </MotifFormField>
            </div>
          </>
        )}
        {currentStep === 2 && !isCallForTableAnalysis && (
          <>
            <MotifTabNavigation defaultActiveKey={0} className="docsTab" variant="alt">
              {vTabs.map((vTab) => (
                <MotifTabControl
                  key={vTab.id}
                  onClick={() => handleTabClick(vTab)}
                  className='btnDocsTab'
                  disabled={((vTab.elementId === 'idTabContentDeleted') && (delDocuments.length === 0))}
                >
                  <span className="tabTitle">{vTab.title}</span>
                  {
                    (vTab.elementId === 'idTabContentDeleted') && (delDocuments.length === 0) &&
                    <MotifTooltip
                      trigger={<img src={iconInfo} alt="info" className="iconInfo" />}
                      allowHover={true}
                      allowClick={false}
                      hideCloseButton={true}
                      contentClassName="tooltipWithoutClose"
                    >
                      No deleted <br />documents found
                    </MotifTooltip>
                  }
                </MotifTabControl>
              ))}
            </MotifTabNavigation>
            <div className="tabContentActive" id="idTabContentActive">
              {documents.map(
                (docs) =>
                  docs.status === "Complete" && (
                    <MotifCheckbox
                      disabled={isCheckBoxDisabled}
                      key={docs.documentId}
                      id={docs.documentId}
                      value={docs.documentName}
                      onChange={(e) => {
                        handleDocumentSelectList(docs);
                      }}
                      checked={
                        selectedDocuments.findIndex(element =>
                          (element.document_UUID === docs.document_UUID)
                        ) !== -1
                      }
                    >
                      {docs.documentName}
                    </MotifCheckbox>
                  )
              )}
            </div>
            <div className="tabContentDeleted" style={{display:'none'}} id="idTabContentDeleted">
              {delDocuments.map((doc, i) => (
                <div className="doc-list" key={doc.documentId}>
                  <div className="document-item deleted">
                    <div className="document-name">{doc.documentName}</div>
                  </div>
                </div>
              ))}
              <div className="tabContentDeletedContent">The documents above were previously selected for Line Analysis, but have since been deleted and therefore will no longer be considered in generating Line Analysis.</div>
            </div>
          </>
        )}
        {currentStep === 2 && isCallForTableAnalysis && (
          <div>
            <div>
              Table commentary will be initiated upon clicking ‘Confirm’. Would
              you like to see the Commentary on the Excel?
            </div>
            <div>&nbsp;</div>
            <div>
              <MotifCheckbox
                id="chkLineCommentry"
                onChange={(e) => onExcelOutputChange(e)}
                checked={excelOutput}
              >
                <span className="lblLineCommentaryCheck">
                  Table commentary Excel
                </span>
              </MotifCheckbox>
            </div>
          </div>
        )}
      </MotifModalBody>
      <MotifModalFooter className="analysisSettingsFooter">
        {(currentStep === 2) && showMessage && !isCheckBoxDisabled && (
          <MotifToast
            id="docWarning"
            variant="warning"
            actionName=""
            position=""
            onClose={() => {
              onToastcloseclick();
            }}
            className="docWarning"
            icons={{
              toast: <img src={iconWarning} alt="Warning" />
            }}
          >
            <div>
              This selection may result in the Line Analysis taking longer to complete.
            </div>
          </MotifToast>
        )}
        {(currentStep === 2) && showMessage && isCheckBoxDisabled && (
          <MotifToast
            id="docWarning"
            variant="warning"
            actionName=""
            position=""
            onClose={() => {
              onToastcloseclick();
            }}
            className="docWarning"
            icons={{
              toast: <img src={iconWarning} alt="Warning" />
            }}
          >
            <div>
              Line Analysis on this table is currently in progress. Document selection cannot be changed until this process in complete.
            </div>
          </MotifToast>
        )}
        {currentStep === 1 && (
          <MotifButton
            onClick={onClose}
            type="button"
            variant="secondary"
            className="btnCancel"
          >
            Cancel
          </MotifButton>
        )}

        {currentStep === 1 && (
          <MotifTooltip
            trigger={
              <MotifButton
                type="button"
                className="btnNext"
                disabled={
                  !selectedPromptType ||
                  !selectedPrompt ||
                  isNextButtonDisabled
                }
                onClick={handleNextClick}
              >
                Next
              </MotifButton>
            }
            allowHover={
              !selectedPrompt
            }
            allowClick={false}
            hideCloseButton={true}
            placement="left"
            contentClassName="tooltipWithoutClose"
          >
            Select a prompt to continue
          </MotifTooltip>
        )}
        {currentStep === 3 && isCallForTableAnalysis === false && (
          <MotifButton
            onClick={handleBackClick}
            type="button"
            variant="secondary"
            className="btnBack"
          >
            Back
          </MotifButton>
        )}
        {currentStep === 2 && (
          <MotifButton
            onClick={onClose}
            type="button"
            variant="secondary"
            className="btnCancel"
          >
            Cancel
          </MotifButton>
        )}
        {currentStep === 2 && (
          <MotifTooltip
            trigger={
              <MotifButton
                type="button"
                className="btnConfirm"
                disabled={isConfirmDisabled}
                onClick={!fromUploadProcess ? handleConfirmClick : handleConfirmForUploadQANFlow}
              >
                Confirm
              </MotifButton>
            }
            allowHover={!isCallForTableAnalysis}
            allowClick={false}
            hideCloseButton={true}
            placement="left"
            contentClassName="tooltipWithoutClose"
          >
            Click to initiate Line Analysis
          </MotifTooltip>
        )}
      </MotifModalFooter>
    </MotifModal>
  );
};

AnalysisSettings.propTypes = {
  initSource: PropTypes.string,
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  fromUploadProcess: PropTypes.bool,
  setOpenModalForUploadProgress: PropTypes.func,
  closeModal: PropTypes.func,
  onConfirm: PropTypes.func,
  lineAnalysisStatus: PropTypes.string,
  documentList: PropTypes.array,
  isCallForTableAnalysis: PropTypes.bool,
  selectedTable: PropTypes.object,
  exportedFileName: PropTypes.string,
  receiveUploadQnAStatus: PropTypes.func,
  setDisplayUploadQAModal: PropTypes.func,
  setUploadQALoader: PropTypes.func
};

export default AnalysisSettings;