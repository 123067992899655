/**
 * Delete a  relationship 
 * Created On: 21-08-2024
 */

import MotifModal, { MotifModalHeader, MotifModalBody } from '@ey-xd/motif-react/Modal';
import { MotifModalFooter } from "@ey-xd/motif-react";
import MotifButton from '@ey-xd/motif-react/Button';
import React from "react";
import warningCircle from '../../../../assets/images/warning.svg';
import "./DeleteTableModal.scss"


const DeleteTableModal = ({ onClose, handleDelete }) => {
    return (
        <div>
        <MotifModal id="deleteWarningTableModal" show={true} size="sm"

            focusTrapOptions={{
                tabbableOptions: {
                    displayCheck: 'none',
                },
            }}
            onClose={onClose}
            className='deleteWarningTableModal confirmModal modalMotifFooterModal'
        >
            <MotifModalHeader
                className='deleteWarningTableHeader'
                closeButtonProps={{
                    'aria-label': 'Close',
                    title: 'Close'
                }}
            >
                <div className='closeSymbolContainer'>
                    <img className='warningCircle' alt='icon' src={warningCircle} />
                </div>
                <div className='deleteTextTable'>Delete Table</div>
            </MotifModalHeader>
            <MotifModalBody className='deleteWarningTableBody'>
                <React.Fragment key=".0">

                    <div className='deleteWarningTableLines'>
                        <span >
                            The table and associated commentary will be removed. Click ‘Confirm’ to proceed.
                        </span>
                    </div>
                </React.Fragment>
            </MotifModalBody>
            <MotifModalFooter className='deleteWarningTableFooter'>
                <React.Fragment key=".0">
                    <MotifButton
                        className='btnCancel '
                        label="btnCancel"
                        size="small"
                        type="button"
                        variant="secondary"
                        onClick={onClose}
                    >
                        Cancel
                    </MotifButton>
                    <MotifButton
                        label="btnConfirm"
                        size="small"
                        type="button"
                        variant="primary"
                        className='btnConfirm'
                        onClick={handleDelete}
                    >
                        Confirm
                    </MotifButton>
                </React.Fragment>
            </MotifModalFooter>
        </MotifModal>
    </div>
    );
};
export default DeleteTableModal;