import axios from "../services/axiosService.js";
import CommonService from "../services/commonService.js";

class ProjectService {
  commonService = new CommonService();
  constructor() {
    this.apiHostEnv = process.env.REACT_APP_HOSTING_ENV;
    this.apiEndpoint = process.env.REACT_APP_WEB_API_ENDPOINT;
    this.webApiFolder = process.env.REACT_APP_WEB_API_FOLDER;
    this.apiEndpointAU = process.env.REACT_APP_WEB_API_ENDPOINT_AU;
    this.apiEndpointEU = process.env.REACT_APP_WEB_API_ENDPOINT_EU;
    this.apiEndpointUS = process.env.REACT_APP_WEB_API_ENDPOINT_US;
    this.platformApiFolder = process.env.REACT_APP_PLATFORM_API_FOLDER;
  }

  /**
   * This method gets all the projects user has access to.
   * @returns Array of projects
   */
  GetProjectMasterData = async (email, country) => {
    const payload = {
      email: email,
      country: country
    }
    try {
      if (this.apiHostEnv === "UAT" || this.apiHostEnv === "PROD") {
        const apiUrlAU = this.apiEndpointAU + this.webApiFolder;
        const apiUrlEU = this.apiEndpointEU + this.webApiFolder;
        const apiUrlUS = this.apiEndpointUS + this.webApiFolder;
        let dataAll = [];
        const dataAU = await axios.post(apiUrlAU + "/Project/GetProjectMasterData", payload).then(response => response?.data);
        const dataEU = await axios.post(apiUrlEU + "/Project/GetProjectMasterData", payload).then(response => response?.data);
        const dataUS = await axios.post(apiUrlUS + "/Project/GetProjectMasterData", payload).then(response => response?.data);
        if (dataAU) {
          dataAll = [...dataAll, ...dataAU];
        }
        if (dataEU) {
          dataAll = [...dataAll, ...dataEU];
        }
        if (dataUS) {
          dataAll = [...dataAll, ...dataUS];
        }
        return dataAll;
      } else {
        const apiUrl = this.apiEndpoint + this.webApiFolder;
        const data = await axios.post(apiUrl + "/Project/GetProjectMasterData", payload).then(response => response?.data);
        return data;
      }
    } catch (error) {
      this.commonService.logException(
        error,
        "projectService",
        "GetProjectMasterData"
      );
    }
  }

  /**
   * This method gets all the projects user has access to.
   * @returns Array of projects
   */
  GetProjectThresholdList = async (userId) => {
    const payload = {
      userId: userId
    }
    try {
      if (this.apiHostEnv === "UAT" || this.apiHostEnv === "PROD") {
        const apiUrlAU = this.apiEndpointAU + this.platformApiFolder;
        const apiUrlEU = this.apiEndpointEU + this.platformApiFolder;
        const apiUrlUS = this.apiEndpointUS + this.platformApiFolder;
        let dataAll = [];
        const dataAU = await axios.post(apiUrlAU + "/userprojectlist", payload).then(response => response?.data);
        const dataEU = await axios.post(apiUrlEU + "/userprojectlist", payload).then(response => response?.data);
        const dataUS = await axios.post(apiUrlUS + "/userprojectlist", payload).then(response => response?.data);
        if (dataAU) {
          dataAll = [...dataAll, ...dataAU];
        }
        if (dataEU) {
          dataAll = [...dataAll, ...dataEU];
        }
        if (dataUS) {
          dataAll = [...dataAll, ...dataUS];
        }
        return dataAll;
      } else {
        const apiUrl = this.apiEndpoint + this.platformApiFolder;
        const data = await axios.post(apiUrl + "/userprojectlist", payload).then(response => response?.data);
        return data;
      }
    } catch (error) {
      this.commonService.logException(
        error,
        "projectService",
        "GetProjectThresholdList"
      );
    }
  }

}

export default ProjectService