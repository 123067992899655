
import React, { useState} from 'react';
import PropTypes from 'prop-types';
import './DisplayQAModal.scss';


//  Motif controls
import MotifModal, { MotifModalHeader, MotifModalBody, MotifModalFooter } from '@ey-xd/motif-react/Modal';
import MotifTabNavigation, { MotifTabControl } from '@ey-xd/motif-react/TabNavigation';
import MotifButton from '@ey-xd/motif-react/Button';
import iconExport from "../../../../assets/images/download.svg";
import iconUpload from "../../../../assets/images/upload.svg";


const DisplayQAModal = (props) => {
    const { openModal, setOpenModal, fileName, showExportQnAClick, lineAnalysisStatus, showUploadQnAClick,
        showUploadQnALoader, uploadconfirmation } = props;
    /**
     * Custom constants
     */
    const vTabs = [
        {
            "id": 1,
            "elementId": "idTabContentExport",
            "title": "Export"
        },
        {
            "id": 2,
            "elementId": "idTabContentUpload",
            "title": "Upload"
        }
    ];
    const [selectedTab, setSelectedTab] = useState(vTabs[0].elementId);

    /**
     * Function to switch tabs
     * @param {*} tab 
     */
    const handleTabClick = (tab) => {
        setSelectedTab(tab.elementId);
    };

    return (
        <MotifModal
            show={openModal}
            onClose={() => setOpenModal(!openModal)}
            focusTrapOptions={{
                tabbableOptions: {
                    displayCheck: "none",
                },
            }}
            className='thesholdsModal genericModal modalMotifFooterModal'
            size='xl'
        >
            <MotifModalHeader
                closeButtonProps={{
                    'aria-label': 'Close',
                    title: 'Close'
                }}
                className='motif-modal-header '
            >
                <div className='thresholdsHead' data-testid='testIdTitle'>Q&A</div>
            </MotifModalHeader>
            <MotifModalBody>
                <MotifTabNavigation defaultActiveKey={0} variant="alt" className='thresholdsTab'>
                    {
                        vTabs.map((vTab) =>
                            <MotifTabControl
                                key={vTab.id}
                                onClick={() => handleTabClick(vTab)}
                                className='btnThresholdsTab'
                                data-testid={'testIdTab' + vTab.id}
                                disabled={
                                    (
                                        (lineAnalysisStatus === 'In-Progress' ||
                                            uploadconfirmation || showUploadQnALoader) &&
                                        (vTab.elementId !== selectedTab)
                                    )
                                }
                            >
                                {vTab.title}
                            </MotifTabControl>
                        )
                    }
                </MotifTabNavigation>
                <div id='idTabContentExport' style={{ display: selectedTab === 'idTabContentExport' ? 'block' : 'none' }}>
                    <div className="file-export">
                        <div className="file-export-content">
                            <span className="file-name">
                                {fileName}
                            </span>

                            <MotifButton
                                className="export-btn"
                                data-testid="testIdBtnDocument"
                                onClick={() => showExportQnAClick()}
                            >
                                {<img src={iconExport} alt="Source Documents" />}

                            </MotifButton>
                        </div>
                    </div>
                </div>
                <div id='idTabContentUpload' style={{ display: selectedTab === 'idTabContentUpload' ? 'block' : 'none' }}>
                    <div className="file-export">
                        <div className="file-export-content">
                            <span className="file-name">
                                {fileName}
                            </span>

                            <MotifButton
                                className="upload-btn"
                                data-testid="testIdBtnDocument"
                                onClick={() => showUploadQnAClick()}
                            >

                                {<img src={iconUpload} alt="Source Documents" />}

                            </MotifButton>
                        </div>
                    </div>
                </div>
            </MotifModalBody>
            <MotifModalFooter className='thresholdsFooter'>
                <MotifButton
                    onClick={() => setOpenModal(!openModal)}
                    type="button"
                    variant="secondary"
                    className='btnCancel'
                    data-testid='testIdCancel'
                >
                    Close
                </MotifButton>
            </MotifModalFooter>
        </MotifModal>
    );
};

DisplayQAModal.propTypes = {
    openModal: PropTypes.bool,
    setOpenModal: PropTypes.func,
    closeModal: PropTypes.func
};

export default DisplayQAModal;