import axios from "./axiosService.js";
import CommonService from "./commonService.js";

class ThresholdService {
    commonService = new CommonService();
    constructor() {
        
        this.platformApiPointer = process.env.REACT_APP_PLATFORM_API_POINTER;
        this.platformApiFolder = process.env.REACT_APP_PLATFORM_API_FOLDER;

        this.webApiPointer = process.env.REACT_APP_WEB_API_POINTER;
        this.webApiFolder = process.env.REACT_APP_WEB_API_FOLDER;

        //  Headers
        this.headers = { "Content-type": "application/json; charset=UTF-8" };

        //  API url methods
        this.apiUrl = {
            syncProject: '/syncproject',
            syncProjectCheck: '/syncproject_check',
            updateGlobalThreshold: '/updateGlobalThreshold',
            updateTableThreshold: '/updateTableThreshold',
            getGlobalThreshold: '/projects/getThreshold'
        }

        //  List of API endpoints to point to local mock data
        this.mockApiUrl = [
            // 'getGlobalThreshold'
        ]
    }

    /**
     * Function to get add project wise threshold data for the first time
     * @param {*} thresholddata 
     * @returns response
     */
    async AddThresholdToProject(projectThresholdData) {
        const vApiEndpoint = this.commonService.getAPIUrl();
        const vUrl = vApiEndpoint + this.platformApiFolder + this.apiUrl.syncProject;
        const addThresholdrequest = {
            name: projectThresholdData.name,
            projectId: projectThresholdData.projectId,
            globalThresholdValue:projectThresholdData.globalThresholdValue,
            globalThresholdPercent: projectThresholdData.globalThresholdPercent,
            createdBy: projectThresholdData.createdBy,
            users: projectThresholdData.users,
            projectCategory: projectThresholdData.projectCategory
        };

        const response = await axios
            .post(
                vUrl,
                addThresholdrequest
            )
            .catch((error) => {
                this.commonService.logException(
                    error,
                    "Project Threshold",
                    "Add Threshold To Project"
                );
            });
        return response.status;    }

     /**
     * Function to get projectwise threshold data
     * @param {*} projectId 
     * @returns response
     */
     async getThresholdForProject(projectId,userId,userRole) {
            const vApiEndpoint = this.commonService.getAPIUrl();
            const vUrl = vApiEndpoint + this.platformApiFolder + this.apiUrl.syncProjectCheck;
            const getThresholdrequest = {
                projectId: projectId,
                userId: userId,
                userRole: userRole
            };
            const response = await axios
            .post(
                vUrl,
                getThresholdrequest
            )
            .catch((error) => {
                this.commonService.logException(
                    error,
                    "Project Threshold",
                    "Get Threshold For Project"
                );
            });
        return response?.data; 
        }

    /**
     * Function to update Global threshold settings
     * @param {*} vData 
     * @returns 
     */
    async updateGlobalThreshold(vType, vData) {
        if (this.platformApiPointer.toLowerCase() === 'local') {
            let vResult;
            if (vType === 'Global') {
                vResult = { "message": "200" };
            } else {
                vResult = [["200","Table Level threshold Setting is Sucessful"]];
            }
            return Promise.resolve(vResult);
        } else {
            const vApiEndpoint = this.commonService.getAPIUrl();
            const vApiUrl = (vType === 'Global') ? this.apiUrl.updateGlobalThreshold : this.apiUrl.updateTableThreshold;
            const vUrl = vApiEndpoint + this.platformApiFolder + vApiUrl;
            const vParams = {
                method: "post",
                url: vUrl,
                headers: this.headers,
                data: JSON.stringify(vData)
            };
            const response = await axios.request(vParams).catch((error) => {
                this.commonService.logException(
                    error,
                    "Threshold Service",
                    "Update Global Threshold Settings"
                );
            });
            return response?.data;            
        }
    }

    /**
     * Function to get Global threshold settings
     * @param {*} vData 
     * @returns 
     */
    async getGlobalThreshold(vData) {
        if (
            (this.platformApiPointer.toLowerCase() === 'local') ||
            (this.mockApiUrl.includes('getGlobalThreshold'))
        ) {
            return Promise.resolve({
                "GlobalThreshold_value": 100,
                "GlobalThreshold_percent": 5
            });
        } else {
            const vApiEndpoint = this.commonService.getAPIUrl();
            const vUrl = vApiEndpoint + this.platformApiFolder + this.apiUrl.getGlobalThreshold;
            const vParams = {
                method: "post",
                url: vUrl,
                headers: this.headers,
                data: JSON.stringify(vData)
            };
            const response = await axios.request(vParams).catch((error) => {
                this.commonService.logException(
                    error,
                    "Threshold Service",
                    "Get Global Threshold Settings"
                );
            });
            return response?.data;            
        }
    }
}
export default ThresholdService;