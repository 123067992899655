import axios from "./axiosService";
import CommonService from "./commonService";

class PromptLibraryService {
    commonService = new CommonService();

    constructor(){
        this.apiEndpoint = process.env.REACT_APP_WEB_API_ENDPOINT;
        this.apiFolder = process.env.REACT_APP_PLATFORM_API_FOLDER;
        this.apiUrl = this.apiEndpoint + this.apiFolder;

        //  List of API endpoints to point to local mock data
        this.mockApiUrl = 
        [
            // "getSystemPrompt"
        ];
    }

   
  async getSystemPrompt(userId) {
    
      
     
        const request = { "userId": userId , "promptType":"system"};
        const response = await axios.post(this.apiUrl + '/fetchSystemPrompts', request)
            .catch((reason) => {
              this.commonService.logException(reason, "PromptLibraryService", "getSystemPrompt");
            });
        return response?.data
    
  }  
    

  async getCentralPrompt(userId) {
    
   
      const request = { "userId": userId , "promptType":"central"};
      const response = await axios.post(this.apiUrl + '/fetchCentralpPrompts', request)
          .catch((reason) => {
            this.commonService.logException(reason, "PromptLibraryService", "getCentralPrompt");
          });
      
      return response?.data
  
}

    getPersonalPrompts = async (userId) => {
        const request = { "userId": userId, "promptType":"personal" };
        const response = await axios.post(this.apiUrl + '/fetchCentralpPrompts', request)
            .catch((reason) => {
              this.commonService.logException(reason, "PromptLibraryService", "getPersonalPrompts");
            });
        return response?.data
    }

    getPersonalPromptById = async (promptId) => {
        const request = { "id": promptId }
        const response = await axios.post(this.apiUrl + '/getPrompts', request)
            .catch((reason) => {
              this.commonService.logException(reason, "PromptLibraryService", "getPersonalPromptById");
            });
        return response?.data;
    }

    getCentralPromptById = async (promptId) => {

      //  return Promise.resolve(tmp_central_prompt_data);

        const request = { "id": promptId }
        const response = await axios.post(this.apiUrl + '/getPrompts', request)
            .catch((reason) => {
              this.commonService.logException(reason, "PromptLibraryService", "getCentralPromptById");
            });
        return response?.data;
    }

    savePersonalPrompt = async (updatedPrompt) => {
        return (await axios.post(this.apiUrl + '/editPersonalPrompts', updatedPrompt)
            .catch((error) => {
                this.commonService.logException(error, "PromptLibraryService", "savePersonalPrompt");
            }));
    }

    saveCentralPrompt = async (updatedPrompt) => {
        return (await axios.post(this.apiUrl + '/EditCentralPrompt', updatedPrompt)
            .catch((error) => {
                this.commonService.logException(error, "PromptLibraryService", "saveCentralPrompt");
            }));
    }

    deletePersonalPrompt = async (promptId) => {
        const request = { "id": promptId, "disabled": "true" };
        return (await axios.post(this.apiUrl + '/deletePersonalPrompts', request)
            .catch((error) => {
                this.commonService.logException(error, "PromptLibraryService", "deletePersonalPrompt");
            }));
    }

    addPersonalPrompt = async (newPersonalPrompt) => {
        return (await axios.post(this.apiUrl + '/RoleBasedAddPrompt', newPersonalPrompt)
            .catch((error) => {
                this.commonService.logException(error, "PromptLibraryService", "addPersonalPrompt");
            }));
    }

    addCentralPrompt = async (newCentralPrompt) => {
        return (await axios.post(this.apiUrl + '/RoleBasedAddPrompt', newCentralPrompt)
            .catch((error) => {
                this.commonService.logException(error, "PromptLibraryService", "addCentralPrompt");
            }));
    }

    copyCentralPromptToPersonal = async (userId,promptId,promptName) => {
        const request = { "projectId": "dummy", "id": promptId, "promptName": promptName, "createdBy": userId };

        const response = await axios.post(this.apiUrl + '/CopyPromptToPersonal', request)
            .catch((reason) => {
              this.commonService.logException(reason, "PromptLibraryService", "getCentralPromptById");
            });
        return response;
    }

    saveSystemPrompt = async (updatedPrompt) => {
        return (await axios.post(this.apiUrl + '/editSystemPrompts', updatedPrompt)
            .catch((error) => {
                this.commonService.logException(error, "PromptLibraryService", "savePersonalPrompt");
            }));
    }
}

export default PromptLibraryService;